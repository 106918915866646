/* You can add global styles to this file, and also import other style files */
@import 'app/core/styles/base.scss';
@import 'app/core/styles/colors.scss';
@import 'app/core/styles/fonts.scss';
@import 'app/core/styles/variables.scss';
// @import '~primeicons/primeicons.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'material-icons/iconfont/material-icons.css';
@import '~froala-editor/css/froala_editor.pkgd.min.css';
@import '~froala-editor/css/third_party/embedly.min.css';
@import 'ngx-toastr/toastr';

body { //TODO: return that back
  // overflow: hidden;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}

.toast-container> :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-success,
.toast-success button {
  background-color: #80ffaa !important;
  color: #00b33c !important;
}

.toast-error,
.toast-error button {
  background-color: #ffc2b3 !important;
  color: #ff3300 !important;
}

.toast-info,
.toast-info button {
  background-color: #b3ecff !important;
  color: #33ccff !important;
}

.toast-warning,
.toast-warning button {
  background-color: #ffeb99 !important;
  color: #ffcc00 !important;
}

.ngx-toastr,
.toast-toastr button {
  background-color: #c1c1c1;
  pointer-events: auto;
  padding: 15px 15px 10px 50px !important;
  width: 350px !important;
}

.loading-container {
  display: flex;
  flex-wrap: wrap;
}

.loading {
  height: 189px;
  width: 189px;
  position: relative;
  margin: 0 15px 15px 0;
  padding: 15px;
  border: 1px #ff9800 solid;
  border-radius: 4px;
}

.loading p {
  margin: 0 0 10px 0;
}
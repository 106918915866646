/**Font-Size**/

/// Represents the thin font weight in the system
$font-weight-thin: 300 !default;

/// Represents the norm font weight in the system
$font-weight-normal: 400 !default;

//Represent medium font weigth in the system
$font-weight-medium: 500 !default;

/// Represents the semi font weight in the system
$font-weight-semi-bold: 600 !default;

/// Represents the bold font weight in the system
$font-weight-bold: 700 !default;


/**Font-Weight**/

/// Represents the thin font weight in the system
$font-weight-thin: 300 !default;

/// Represents the norm font weight in the system
$font-weight-normal: 400 !default;

//Represent medium font weigth in the system
$font-weight-medium: 500 !default;

/// Represents the semi font weight in the system
$font-weight-semi-bold: 600 !default;

/// Represents the bold font weight in the system
$font-weight-bold: 700 !default;
